import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createDocumentType = createAsyncThunk(
    'documentTypes/createDocumentType',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'DocumentTypes/createDocumentType', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getDocumentTypes = createAsyncThunk(
    'documentTypes/getDocumentTypes',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getDocumentTypesUrl = Constants.API_URL + 'DocumentTypes/getDocumentTypes';
            return await axios.post(getDocumentTypesUrl, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const deleteDocumentType = createAsyncThunk(
    'documentTypes/deleteDocumentType',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'DocumentTypes/deleteDocumentType', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateDocumentType = createAsyncThunk(
    'documentTypes/updateDocumentType',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'DocumentTypes/updateDocumentType', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const documentTypesSlice = createSlice({
    name: 'documentTypes',
    initialState: {
        isGetDocumentTypesFetching: false,
        isGetDocumentTypesSuccess: false,
        isGetDocumentTypesError: false,
        getDocumentTypesErrorMessage: '',

        isCreateDocumentTypeFetching: false,
        isCreateDocumentTypeSuccess: false,
        isCreateDocumentTypeError: false,
        createDocumentTypeErrorMessage: '',
        documentTypes: [],

        isUpdateDocumentTypeFetching: false,
        isUpdateDocumentTypeSuccess: false,
        isUpdateDocumentTypeError: false,
        updateDocumentTypeErrorMessage: '',

        isDeleteDocumentTypeFetching: false,
        isDeleteDocumentTypeSuccess: false,
        isDeleteDocumentTypeError: false,
        deleteDocumentTypeErrorMessage: '',

    },
    reducers: {
        clearCreateDocumentTypeState: (state) => {
            state.isCreateDocumentTypeError = false;
            state.isCreateDocumentTypeSuccess = false;
            state.isCreateDocumentTypeFetching = false;
            state.createDocumentTypeErrorMessage = "";
            return state;
        },
        clearUpdateDocumentTypeState: (state) => {
            state.isUpdateDocumentTypeError = false;
            state.isUpdateDocumentTypeSuccess = false;
            state.isUpdateDocumentTypeFetching = false;
            state.updateDocumentTypeErrorMessage = "";
            return state;
        },
        clearDeleteDocumentTypeState: (state) => {
            state.isDeleteDocumentTypeError = false;
            state.isDeleteDocumentTypeSuccess = false;
            state.isDeleteDocumentTypeFetching = false;
            state.deleteDocumentTypeErrorMessage = "";
            return state;
        },
        clearGetDocumentTypesState: (state) => {
            state.isGetDocumentTypesError = false;
            state.isGetDocumentTypesSuccess = false;
            state.isGetDocumentTypesFetching = false;
            state.getDocumentTypesErrorMessage = "";
            return state;
        },
    },
    extraReducers: {
        [createDocumentType.fulfilled]: (state, { payload }) => {
            state.isCreateDocumentTypeFetching = false;
            state.isCreateDocumentTypeSuccess = true;
        },
        [createDocumentType.pending]: (state) => {
            state.isCreateDocumentTypeFetching = true;
        },
        [createDocumentType.rejected]: (state, { payload }) => {
            state.isCreateDocumentTypeFetching = false;
            state.isCreateDocumentTypeError = true;
            state.createDocumentTypeErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateDocumentType.fulfilled]: (state, { payload }) => {
            state.isUpdateDocumentTypeFetching = false;
            state.isUpdateDocumentTypeSuccess = true;
        },
        [updateDocumentType.pending]: (state) => {
            state.isUpdateDocumentTypeFetching = true;
        },
        [updateDocumentType.rejected]: (state, { payload }) => {
            state.isUpdateDocumentTypeFetching = false;
            state.isUpdateDocumentTypeError = true;
            state.updateDocumentTypeErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [deleteDocumentType.fulfilled]: (state, { payload }) => {
            state.isDeleteDocumentTypeFetching = false;
            state.isDeleteDocumentTypeSuccess = true;
        },
        [deleteDocumentType.pending]: (state) => {
            state.isDeleteDocumentTypeFetching = true;
        },
        [deleteDocumentType.rejected]: (state, { payload }) => {
            state.isDeleteDocumentTypeFetching = false;
            state.isDeleteDocumentTypeError = true;
            state.deleteDocumentTypeErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getDocumentTypes.fulfilled]: (state, { payload }) => {
            state.isGetDocumentTypesFetching = false;
            state.documentTypes = payload && payload.data || [];
            state.isGetDocumentTypesSuccess = true;
            return state;
        },
        [getDocumentTypes.rejected]: (state, { payload }) => {
            state.isGetDocumentTypesFetching = false;
            state.isGetDocumentTypesError = true;
            state.getDocumentTypesErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getDocumentTypes.pending]: (state) => {
            state.isGetDocumentTypesFetching = true;
        },
    }
});

export const { clearCreateDocumentTypeState, clearGetDocumentTypesState, clearUpdateDocumentTypesOrderState, clearDeleteDocumentTypeState, clearUpdateDocumentTypeState } = documentTypesSlice.actions;

export const documentTypesSelector = (state) => state.documentTypes;

export default documentTypesSlice.reducer;